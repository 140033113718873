import React, { useEffect,useContext,useState } from "react";
import Context from './context';
import { useLocation } from 'react-router-dom'


export default function Header(props) {
	
	const [bandierine, setBandierine] = useState(false);
	const [displayBandierine, setDisplayBandierine] = useState(true);

	const [Icon, setIcon] = useState();
	//const country = ["da","de","en","es","fi","fr","it","no","pl","pt","ru","sv","tr","nl","cs","ko","ja","zh"]
	const country = ["en","es","it"]
	
	const context = useContext(Context)

  var location = useLocation();
    location = location.pathname.split("/")

    useEffect(() => {  
 
      if(location[1]=="privacy" || location[1]=="terms" || location[1]=="faq"){ 
        setDisplayBandierine(false)
      }
    },[location]);

	
	 useEffect(() => { 
		const icona = "icon__"+context.linguaAttuale
		setIcon(icona)

   },[context.linguaAttuale]);
	
	function IconeBandiere(props){
		 const country = props.iso
		 const icona = "icon__"+country
		 return (
			<li class="languages_item js-languages_item" data-js-language={country} onClick={()=>context.SwitchLanguage(country)}>
			  <a href="#" class="languages_link">
				<i class={"language_icon icon__flag " + icona}></i>
				<span class="languages_text">{country}</span>
			  </a>
			</li>)
	}

	return (   <header class="header">
    <div class="center">
      <div class="header_wrap">
        <div class="header_logo">
                      <a href="/" class="logo logo-svg logo__svg">
              <div class="logo-svg-wrapper logo_svg color-modifier">


              </div>
              <div class="logo-svg-text logo_svg-text color-modifier">
			  <span style={{"font-family": "Helvetica"}}>{window.location.hostname}</span></div>
            </a>
                  </div>
                  <div class="header_languages">
            <div class="languages">
              <form class="languages_form">
                <input type="hidden" id="userToken" name="userToken" value="" class="js-user-token" />
                <input type="hidden" id="selectedLanguage" name="selectedLanguage" value="" class="js-selected-language" />
                <div onClick={() => setBandierine(prevCheck => !prevCheck)} className={bandierine && ( "languages_wrap js-languages is-active" : "languages_wrap js-languages" )} >
                  <div class="languages_title">
                    <i class={"language_icon icon__flag " + Icon}></i>
                    <span class="languages_text">{context.linguaAttuale}</span>
                  </div>
					<ul class="languages_list">
					
						{
							country.map(el =>
								<IconeBandiere iso={el}/>
							)
						}
						
                    </ul>
                  <div class="languages_overlay js-languages_overlay"></div>
                </div>
              </form>
            </div>
          </div>
              </div>
    </div>
  </header>)


}
