export const translate = 
    {
		"sezione1": {
			"benvenuto":"Benvenuto all'Assistenza Clienti",
			"faq":"Domande sulla fatturazione",
			"titolo": "Perché c'è un addebito sulla mia carta?",
			"descrizione": "Se hai dubbi sull'addebito effettuato sul saldo della tua carta, ti aiuteremo in tutti i modi possibili a recuperare tutte le informazioni del caso. Utilizza i nostri numeri verdi per ottenere assistenza immediata per qualsiasi problema.",
			"telefono":"+34 932 20 71 48",
			"contattaci":"CONTATTACI",
			"esempio_estratto":"Esempio di estratto conto",
			"per_saperne_di_piu":"Per saperne di più",
			"date_esempio":"14 maggio - 14 agosto"
		},
		"benefits":{
			"garanzia":"Garanzia 100%",
			"rimborso":"Rimborso",
			"desc_rimborso":"Puoi richiedere un rimborso se non sei soddisfatto dei nostri prodotti.",
			"riservetezza":"Riservatezza",
			"desc_riservatezza":"Qualsiasi informazione personale da te fornita con noi è al sicuro.",
			"sicurezza":"Sicurezza",
			"desc_sicurezza":"Tutte le transazioni sono totalmente sicure.",
		},
		"contact_us":{
			"altre_domande":"Altre domande?",
			"desc_domande":"Se hai un problema, il nostro Servizio Assistenza Clienti è qui per aiutarti. Compila tutti i campi in modo da poter saperne di più sulle tue esigenze e rispondere nel più breve tempo possibile.",
			"chiamaci":"Chiamaci ad uno dei nostri numeri verdi",
			"numero":"+34 932 20 71 48",
			"nome":"Nome",
			"email":"Email",
			"numero_user":"Numero di telefono completo con un prefisso nazionale",
			"data_transazione":"Data della transazione",
			"importo":"Importo della transazione",
			"altre_info":"Qualsiasi altra informazione",
			"compila_carta":"Compila il modulo con le prime sei e le ultime quattro cifre della carta su cui è stato effettuato l'addebito:",
			"acconsento_privacy":"Acconsento al trattamento dei miei dati personali al fine di ricevere risposte da parte vostra in merito alla mia richiesta di informazioni.",
			"bottone_contattaci":"CONTATTACI ADESSO",
			"error_name":"Il nome è obbligatorio",
			"error_email":"L'email è richiesta",
			"error_phone":"Il telefono è richiesto",
			"error_data_trans":"La data della transazione è obbligatoria",
			"error_import":"L'importo è richiesto",
			"error_carta":"Inserisci i numeri della carta di credito",
			"error_checkbox":"Accetta i termini e le condizioni",
			"ok":"La tua richiesta è stata inviata con successo",
			"ko":"Ci sono dei problemi, riprova più tardi"
		},
		"footer":{
			"privacy":"Politica sulla Privacy",
			"termini":"Termini",
			"faq":"Domande sulla fatturazione"
		}
	}