import React, { useState,useEffect  } from "react";


export default function Terms() {

	return (<div class="static-page">
  <section class="center">
    <div class="article">
      <div class="text-page_wrap">
        <p></p>

<h1>Terms and Conditions</h1>

<p>1.1 Please read these Terms and Conditions carefully as they apply to your access to and use of the services described in our website.<br/>
1.2 A version of these Terms and Conditions and of any notice given in electronic form shall be admissible in judicial or administrative proceedings to the same extent and subject to the same conditions as other business documents and records originally generated and maintained.<br/>
1.3 The materials contained in this website are provided for general information purposes only and do not claim to be or constitute any form of professional advice. We do not accept any responsibility for any loss which may arise from accessing or reliance on information contained in this website.<br/>
1.4 This website may include hyperlinks to websites operated by other parties and content posted by visitors to our website. We are not responsible for examining or evaluating them and their inclusion does not imply our endorsement of their content. We are therefore not responsible for the content of external Internet sites that link to this website or which are linked from it. Nor are we responsible for any content posted to this website by visitors.<br/>
1.5 The contents of this site are protected by copyright under International Law.<br/>
1.6 Users are permitted to read the contents of this site and make copies for their own personal use only. All other use and copying of any of the contents of this site is prohibited. Copying from websites of third parties is subject to any requirements applicable to those sites.<br/>
1.7 We can, without liability, change or discontinue any part of the Service, temporarily or permanently. We will give you reasonable notice of any such change or discontinuance as is practicable in the circumstances. Notice of any changes to the Service will be posted on the website.<br/>
1.8 We can change these Terms and Conditions at any time without give you prior notice before doing so. We will post the amended Terms and Conditions on the website upon any such change. The amendments become effective upon being posted to the website and any use of the Service occurring after such posting is subject to the amendments. Your continued use of the Service after the posting of the amended Terms and Conditions on the website constitutes your acknowledgement of the Terms and Conditions and its modifications and your agreement to abide and be bound by them. These Terms and Conditions may not otherwise be amended, except in posting of the amended Terms and Conditions on the website.</p>

<h1>2 THE SERVICE</h1>

<p>2.1 We can use our customer support representatives for the support, marketing and improvement of the Service. Our customer support representatives can speak with our members and also to stimulate conversation between members by making introductions or recommendations. This is a vital part of the Service we provide. Should you wish not to be contacted in this way you can alter your preferences by visiting the 'My Account' section of this website.<br/>
2.2 We may, from time to time, send electronic communications to ask how our members are using certain aspects of the Service.<br/>
2.3 There is no guarantee that you will find a date, a companion or an activity partner, or that you will meet any of our members in person.</p>

<h1>3 MEMBERSHIP</h1>

<p>3.1 Registration<br/>
By registering as a member and using any services offered on this website you warrant to us that you are over 18 years of age and that you agree to these Terms and Conditions. If you are not over 18 years of age or do not agree to the Terms and Conditions then you cannot proceed to use our website.<br/>
3.2 Subscription<br/>
There is no joining fee or monthly subscription fee payable for the use of the website. Upon becoming a member of the website you will have access to the free Standard Membership package ("Standard Membership"). Standard Membership gives you a limited access to features of the website. If you want, however, to get full access to all features of the website, you are welcome to buy one of the subscription packages currently available on our site. Your subscription will be automatically extended for successive renewal period of the same duration as the subscription term originally selected, at the then current non-promotional subscription rate. Please note that you must terminate your subscription prior to the end of the subscription period for which you have paid to avoid being charged for the next subscription period. To change or terminate your subscription at any time, please follow the subscription cancellation procedure described in the Help section.</p>

<h1>3.3 Use of website</h1>

<p>You agree to:<br/>
3.3.1 provide accurate and up-to-date information as required by the registration forms on the website;<br/>
3.3.2 update and maintain the accuracy of such information;<br/>
3.3.3 only post material relevant to the users of the website;<br/>
3.3.4 report any misuse of the website or any misleading or inappropriate material on the website or the profiles of other members;<br/>
3.3.5 maintain the security of your password;<br/>
3.3.6 be fully responsible for all use of your account and for any actions that take place using your account<br/>
3.3.7 only post genuine ratings of and comments on other users’ profiles and other content based on your personal experience. Please note that any comments you make, if false, could be defamatory and could lead to legal proceedings being taken against you.<br/>
3.4 You agree not to use the website to:<br/>
3.4.1 impersonate any person or organization or falsely state or otherwise misrepresent yourself, your age or your affiliation with any person or organization;<br/>
3.4.2 upload or share any unsolicited or unauthorized material or advertising;<br/>
3.4.3 upload, publish or display any personal or confidential information of any person or organization without their prior consent;<br/>
3.4.4 solicit or request personal information from anyone under the age of 18 or solicit passwords or personal information for unlawful purposes;<br/>
3.4.5 upload, share or otherwise make available any material that contains viruses or similar software or programs;<br/>
3.4.6 upload, share or otherwise make available any material which may be illegal, discriminatory in any way, of a sexual nature or otherwise offensive or harmful to other users of the website or others;<br/>
3.4.7 upload, share or otherwise make available any material which is deliberately misleading or fraudulent;<br/>
3.4.8 intimidate or harass any person or organization;<br/>
3.4.9 engage in or transmit any unauthorized surveys, contests, chain letters or unsolicited mass distribution of email;<br/>
3.4.10 promote another service, site and/or business or buy or sell products and/or services;<br/>
3.4.11 interfere or disrupt networks connected to the Services or attempt to interfere with the proper functioning of this website (including imposing an unreasonable load on the infrastructure of this website);<br/>
3.4.12 use or attempt to use the account of any other user without the consent of that user or our prior written authorization;<br/>
3.4.13 use, process or disseminate any of the information on this website for any purpose other than the purposes for which the information was provided.<br/>
3.5 You are solely responsible for any content which you upload, publish or display on this website. You warrant that you will not upload, publish or display any content that you did not create or that you do not have permission to upload publish or display. We reserve the right to review all content on the website and delete or remove any content which breaches these Terms and Conditions or which may be offensive, illegal or harmful to the safety of users of the website or to others.<br/>
3.6 By publishing, uploading or displaying content on the website you grant to us, and represent and warrant that you have the right to grant, an irrevocable, worldwide, non-exclusive, sublicensable and royalty free license to use, copy, publish, display, reformat, translate and distribute such content for any purpose in connection with the website, the associated services and the promotion of the website and the Service. You may remove content you have uploaded, published or displayed at any time, whereupon the license granted above will expire, provided that we shall be permitted to retain archived copies of all such content.</p>

<h1>3.7 Security</h1>

<p>3.7.1 Access to your user account with us and the material available to registered users on our website will be gained by using the email address and the password selected by you or provided to you during the registration procedure. You are responsible for ensuring the confidentiality and proper use of your login details and account and for restricting access to your computer and you agree to accept responsibility for all activities that occur under your account or password. In order to assist us with our security policy, you should not leave your computer terminal unattended while you are logged in to our website and should ensure that it is switched off each time you have finished.<br/>
3.7.2 You must tell us immediately if you believe that an unauthorized person knows your login details or has access to your account.<br/>
3.7.3 We reserve the right to refuse service or terminate your account if we suspect an unauthorized person is attempting to access it.</p>

<h1>3.8 Your Profile</h1>

<p>3.8.1 If your profile is removed by us or on termination of your membership, all data stored, including all messages previously sent or received, will be irreversibly deleted. You should therefore save any important information on your own systems.<br/>
3.8.2 If we consider that your profile is inappropriate, or does not comply with these Terms and Conditions, you will be advised of this decision, with reasons, and given a period of time to revise your profile. During this period, your profile may be hidden so that you can still log in and update details but your profile will not be available for other users to view.</p>

<h1>3.9 Termination</h1>

<p>3.9.1 If you wish to terminate your membership at any time please email us using the contact details at the main page of this website.<br/>
3.9.2 We may terminate your membership at any time without prior written notice to you.<br/>
3.9.3 If you have breached these Terms and Conditions we can terminate your membership immediately without prior written notice.<br/>
3.9.4 Upon termination of the Service you agree that we can immediately delete the files in your account and prevent any further access to such files or the Service.</p>

<h1>3.10 Cancellation</h1>

<p>3.10.1 You are free to cancel your subscription at any time. To ensure the security of your account you have to complete all steps of the subscription cancellation procedure in accordance with the security policy of our company. To start your subscription cancellation, enter the “My Settings” section, click on the “Billing History” tab and follow our site instructions to proceed. Once you have done this we will send an email with further instructions to the email address you used to register with us. Should you have any questions, contact us by sending an email.</p>

<h1>4 LIMITATION OF LIABILITY AND INDEMNITY</h1>

<p>4.1 Your attention is drawn specifically to this paragraph.<br/>
4.2 This website or any part of it may not be compatible with your browser or computer configuration and we make no warranty that it is. We make no warranty that our services via this website will be available at any particular time or that your access to our website will be uninterrupted, timely or error free and we will not be responsible if we are unable to provide the website or its services for any reason.<br/>
4.3 If you are accessing our website and services in the course of a business, all implied warranties and conditions are excluded to the maximum extent permitted by law.<br/>
4.4 We shall not be liable for any losses you suffer as a result of unauthorized access to your account until such time as you have informed us of any unauthorized or possible unauthorized use of your login details or of a breach of security.<br/>
4.5 We shall not be liable for (a) any loss of profit, data loss, loss of production, depletion of goodwill and (b) any indirect loss, costs or expenses whatsoever in each case arising out of your use of our website, your use of our services available or advertised on our website or our failure to deliver services contained on our website. Our total liability for losses arising out of your use of this website, your use of the services available through it or our failure to deliver any such services shall be limited to the total cost of the purchase under which the claim arises for any one event or series of connected events or, if no service has been purchased, to $50.<br/>
4.6 If you are accessing our website to use the Services available through it otherwise than in the course of your business, you have certain statutory rights as a consumer regarding the performance of services. These statutory rights are not and will not be affected by any statement contained in these Terms and Conditions (and in particular, any provision which has the effect of limiting our liability to you in the event that any service is defective or fails to correspond with the description given to it on this website shall not apply).<br/>
4.7 You warrant to us that you will not use this website or any other services accessible through your user account with us for any unlawful purposes or in a way that is prohibited by these Terms and Conditions. We may immediately terminate your account and your ability to access this website if you breach any such Terms and Conditions or if we believe that we or our other customers may incur liability from your actions.<br/>
4.8 If you are accessing our website in the course of a business, you agree to indemnify us and to keep us indemnified (except to the extent caused by our negligence, fraud or willful default) from all or any liability or damages we incur in contract, tort (including negligence) or otherwise towards a third party as a result of your use of this website or any of the services accessible through your user account or any content published, uploaded or displayed by you on the website.</p>

<h1>5 OUR CUSTOMER COMPLAINTS POLICY</h1>

<p>We are committed to meeting the highest quality standards in the delivery of the service we provide to our customers. We aim to provide efficient and effective service and take any problems that do arise very seriously indeed. We aim to ensure that any complaints our customers may have are identified quickly and dealt with in accordance with our complaints handling procedure. In the event that you have any cause for complaint with regard to any of our services please email us using the contact details at the main page of this website.</p>

<h1>6 GENERAL</h1>

<p>6.1 If any provision contained in these Terms and Conditions is determined to be invalid or unenforceable the remainder of the Terms and Conditions shall not be affected.<br/>
6.2 You agree that we have the right to assign or sub-contract any or all of our obligations under these Terms and Conditions. You must not, without our prior written consent, assign these Terms and Conditions.<br/>
6.3 The information contained in our website and any services have been prepared in compliance with the laws of country the company is registered in. You should seek appropriate legal advice if you are unsure as to which country’s law or jurisdiction applies to your circumstances.<br/>
6.4 These Terms and Conditions and your use of this website are governed by and construed in accordance with the laws of country the company is registered in. Unless you are using our website and services as a consumer, by agreeing to these Terms and Conditions you consent to all disputes arising out of or relating to your use of this website being decided only by the courts of country the company is registered in. If you are accessing this website from another jurisdiction it is your responsibility to ensure compliance with the laws that apply to that jurisdiction.<br/>
6.5 You are not permitted to assign your rights under these Terms and Conditions.</p>

<h1>What information will be collected?</h1>

<p>The type of information we will collect about you includes:<br/>
1. your name, address, occupation, phone number and email address<br/>
2. the information included in your membership profile and that you provide via our client surveys, your interests and details about the services you specifically request.<br/>
We will never collect sensitive information about you without your explicit written consent.</p>

<h1>How will the information be used?</h1>

<p>The information you provide to us when using this website will be used to administer your user account with us (including notifying you of any updates or services, should you consent to this), customize the site according to your interests, ensure that you are complying with the Terms and Conditions of use and to comply with our statutory and regulatory obligations. By registering a profile you consent to the wider dissemination of the information you provide in that profile to the other users of the site, some of whom may be outside the EEA or US, to achieve your joint objectives. We will keep your personal information confidential except to the extent that you have posted it for publication or display, and the cases when we are compelled to disclose it by law or to comply with an instruction of a regulatory body of competent jurisdiction. We adhere to strict security procedures and your personal information will be held securely to ensure no unauthorized disclosure or access.</p>

<h1>Your Rights</h1>

<p>Should you wish to obtain a copy of the personal data that we hold on you, please send an email to us. If you believe that the information we hold about you is inaccurate or if you wish us to cease processing your data for any particular purpose or purposes, write to us. If you have a complaint regarding any breach of this privacy policy, contact us.</p>

<h1>Tracking</h1>

<p>When visiting our web pages, we generally use tracking technologies which record information about you automatically. This information does not identify you personally and is used to ensure that our web pages stay compatible with a selection of browsers and systems.</p>
      </div>
    </div>
  </section>
</div>)
	

}
