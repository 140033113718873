export const translate = 
    {
		"sezione1": {
			"benvenuto":"Te damos la bienvenida al servicio de atención al cliente",
			"faq":" Preguntas sobre facturación",
			"titolo": "¿Por qué se ha realizado un cargo en mi tarjeta? ",
			"descrizione": "Si tienes alguna consulta sobre un cargo realizado en tu tarjeta, te ayudaremos a acceder a toda la información sobre el cargo. Usa nuestros números gratuitos para recibir ayuda inmediata sobre cualquier problema.",
			"telefono":"+34 932 20 71 48",
			"contattaci":"Contacta con nosotros",
			"esempio_estratto":"Ejemplo de extracto bancario",
			"per_saperne_di_piu":"Más información"
		},
		"benefits":{
			"garanzia":"100% garantizado",
			"rimborso":"Devolución",
			"desc_rimborso":"Puoi richiedere un rimborso se non sei soddisfatto dei nostri prodotti.",
			"riservetezza":"Confidencialidad",
			"desc_riservatezza":"Toda la información personal que nos suministres está segura con nosotros.",
			"sicurezza":"Seguridad",
			"desc_sicurezza":"Todas las transacciones son totalmente seguras. ",

		},
		"contact_us":{
			"altre_domande":"¿Tienes alguna otra pregunta? ",
			"desc_domande":"Nuestro equipo de Atención al Cliente te ayudará si tienes algún problema. Por favor, rellena todos los campos para que tengamos más información sobre lo que necesitas y podamos responderte en el plazo más breve posible. ",
			"chiamaci":"Llámanos a uno de nuestros números gratuitos:",
			"numero":"+34 932 20 71 48",
			"nome":"Nombre",
			"email":"Correo electrónico",
			"numero_user":"Número de teléfono completo con código de país",
			"data_transazione":"Fecha de la transacción",
			"importo":"Importe de la transacción",
			"altre_info":"Otra información",
			"compila_carta":"Por favor rellena el formulario con los seis primeros y los cuatro últimos dígitos de la tarjeta en la que se realizó el cargo:",
			"acconsento_privacy":"Estoy de acuerdo con el procesamiento de mis datos personales con el propósito de que respondan a mi solicitud de información.",
			"bottone_contattaci":"Contacta con nosotros",
			"error_nome":"El nombre es requerido",
			"error_email":"El correo electrónico es obligatorio",
			"error_telefono":"El teléfono es requerido",
			"error_data_trans":"La Fecha de Transacción es requerido",
			"error_importo":"El importo es requerido",
			"error_carta":"Ingrese los números de la tarjeta de crédito",
			"error_checkbox":"Aceptar los términos y condiciones",
			"ok":"Su solicitud ha sido enviada con éxito",
			"ko":"Hay algunos problemas, vuelve a intentarlo más tarde"
		},
		"footer":{
			"privacy":"Política de Privacidad",
			"termini":"Términos",
			"faq":"Preguntas sobre facturación"
		}
	}