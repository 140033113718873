import React, { useEffect,useContext } from "react";
import Context from './context';


export default function Section1(user) {
	
	const  context = useContext(Context)


	var traduzione = context.traduzione.translate.sezione1
	


	return (   <section class="information">
      <div class="information_wrap">
        <div class="information_item information_item__support">
          <div class="center">
            <div class="support">
              <div class="support_wrap">
                <div class="support_consultant">
                  <div class="support_img"></div>
                </div>
                <div class="support_information">
                  <p class="support_text-wrap">
					<span class="support_text">{traduzione.benvenuto}</span>
                  </p>
                  <a href="/billingQuestions" class="support_link" target="_blank">{traduzione.faq}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="information_item information_item__title">
          <div class="center center__title">
            <h1 class="information_title title">{traduzione.titolo}</h1>
            <div class="information_item-decor">
              <div class="information_item-decor-bg"></div>
            </div>
          </div>
        </div>
        <div class="information_item information_item__subtitle">
          <div class="center">
            <h2 class="information_subtitle subtitle">
              {traduzione.descrizione}</h2>
          </div>
        </div>
        <div class="information_item information_item__actions">
          <div class="center">
            <div class="actions">
              <div class="actions_wrap">
                <div class="actions_item actions_item__phone-numbers">
                                      <div class="select-block">
                      <div class="select-block_selected js-selected">
                        <div data-value="" class="select-block_selected-content js-current-option">
                          <div class="select-block_item us">
                            <span class="select-block_item-text">{traduzione.telefono}</span>
                            <div class="select-block_item-wrap">
                              <a href="tel:1-888-897-9509" class="select-block_item-link">
                                <i class="select-block_item-icon phone-icon phone-icon__us"></i>
                                <span class="select-block_item-number"></span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul class="select-block_list js-select-list">
                                                  <li data-value="1" class="select-block_list-item js-select-item">
                            <div class="select-block_item us">
                              <span class="select-block_item-text">{traduzione.telefono}</span>
                              <div class="select-block_item-wrap">
                                <a href="tel:1-888-897-9509" class="select-block_item-link">
                                  <i class="select-block_item-icon phone-icon phone-icon__us"></i>
                                  <span class="select-block_item-number"></span>
                                </a>
                              </div>
                            </div>
                          </li>
                                              </ul>
                    </div>
                                  </div>
            
                <div class="actions_item actions_item__contact-us">
                  <a href="#contactUsBlock" class="actions_item__link js-actions-link-contact-us">
                    <span class="actions_item__icon icon icon__message"></span>
                    <span class="actions_item__name">{traduzione.contattaci}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="information_item information_item__bill">
          <div class="center">
            <div class="bill">
              <div class="bill_wrap js-bill">
                <div class="bill_bookmark js-bill-bookmark">
                  <div class="bill_name"></div>
                </div>
                <div class="bill_content js-bill-content">
                  <div class="bill_close js-bill-close-btn"></div>
                  <div class="bill_watermark">
                    <div class="bill_watermark-item">sample</div>
                    <div class="bill_watermark-item">sample</div>
                    <div class="bill_watermark-item">sample</div>
                    <div class="bill_watermark-item">sample</div>
                    <div class="bill_watermark-item">sample</div>
                    <div class="bill_watermark-item">sample</div>
                    <div class="bill_watermark-item">sample</div>
                    <div class="bill_watermark-item">sample</div>
                  </div>
                  <div class="bill_title">
                    {traduzione.esempio_estratto}</div>
                  <div class="bill_date">
                        {traduzione.date_esempio}              </div>
                  <div class="bill_items">
                    <div class="bill_item bill_item__main">
                      <div class="bill_item-site">
                        <i class="bill_site-icon bill_site-icon__dating"></i>
                        <span class="bill_site-name bill_site-name__dating">{window.location.hostname}</span>
                      </div>
                      <div class="bill_item-theme">dating</div>
                      <div class="bill_item-sum">$39.99</div>
                    </div>
                    <div class="bill_item">
                      <div class="bill_item-site">
                        <i class="bill_site-icon bill_site-icon__coffee"></i>
                        <span class="bill_site-name bill_site-name__coffee">starbucks.com</span>
                      </div>
                      <div class="bill_item-theme">coffee</div>
                      <div class="bill_item-sum">$5.99</div>
                    </div>
                    <div class="bill_item">
                      <div class="bill_item-site">
                        <i class="bill_site-icon bill_site-icon__books"></i>
                        <span class="bill_site-name bill_site-name__books">amazon.com</span>
                      </div>
                      <div class="bill_item-theme">books</div>
                      <div class="bill_item-sum">$4.99</div>
                    </div>
                  </div>
                  <div class="bill_cards">
                    <div class="bill_cards-icon"></div>
                  </div>
                  <div class="bill_action">
                    <a href="#" class="bill_btn btn js-bill-btn">
                      {traduzione.per_saperne_di_piu}                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>)


}
