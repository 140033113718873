import React, { useState,useEffect,createContext  } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Header from './components/header';
import Section1 from './components/section1';
import Benefits from './components/benefits';
import Contactus from './components/contactus';
import Footer from './components/footer';
import Context from './components/context';
import Privacy from "./components/privacy";
import Terms from "./components/terms";
import Faq from "./components/faq";


export default function App() {
	

	const [bandierine, setBandierine] = useState(false);
	const [traduzione, setTraduzione] = useState(LanguageFirstSetup());
	const [linguaAttuale, setLinguaAttuale] = useState(navigator.language || navigator.userLanguage);
	

	const contextValue = {
		traduzione,
		setTraduzione,
		SwitchLanguage,
		linguaAttuale,
		setLinguaAttuale
	}
	
	
		
	function LanguageFirstSetup(){

		var country = navigator.language || navigator.userLanguage

		var notifiche;
		switch(country) {
		case "es":
			notifiche = require('./components/lang/es');
		break;
		case "it":
			notifiche = require('./components/lang/it');
		break;
		case "en":
			notifiche = require('./components/lang/en');
		break;
		default:
			notifiche = require('./components/lang/es');
		} 
		
		return notifiche
	}
	
	
	function SwitchLanguage(country){
		
		setLinguaAttuale(country)	
		var notifiche;

		switch(country) {
		case "es":
			notifiche = require('./components/lang/es');
		break;
		case "it":
			notifiche = require('./components/lang/it');
		break;
		case "en":
			notifiche = require('./components/lang/en');
		break;
		default:
			notifiche = require('./components/lang/es');
		} 
		
		setTraduzione(notifiche)

	}

	
  return (
  <BrowserRouter>
	  <Context.Provider value={contextValue}>
		<Switch>
			
			<Route path="/privacy">
				<Header/>
				<Privacy/>
				<Footer/>
			</Route>
			<Route path="/terms">
				<Header/>
				<Terms/>
				<Footer/>
			</Route>
			<Route path="/faq">
				<Header/>
				<Faq/>
				<Footer/>
			</Route>
			<Route path="/">
				<Header/>
				<main class="main-content">
					<Section1/>
					<Benefits/>
					<Contactus/>
					<Footer/>
				</main>
			</Route>
		</Switch>
	
	  </Context.Provider>
  </BrowserRouter>
  );
}


