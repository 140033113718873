import React, { useState,useEffect  } from "react";


export default function Faq() {

	return (<div class="static-page">
  <section class="center">
    <div class="article">
      <div class="text-page_wrap">
        <h1>How does the Trial membership work?</h1>

<p>We offer a low cost trial membership, which is valid for a specified number of days.<br/>
Trial membership is upgraded automatically to the standard monthly membership unless you cancel it within the trial period.<br/>
Please refer to the Terms and Conditions for further information</p>

<h1>I would like to change my subscription plan</h1>

<p>If you wish to switch to another subscription type, you need to cancel your current subscription and re-join after your current subscription expires.</p>

<h1>What is Recurring Payment?</h1>

<p>We operate a repeat billing policy. This means that once your initial membership period has expired, for your convenience, your membership will automatically be renewed at the same rate that you signed up for. That means even if our prices rise, your renewal costs are fixed at your signup price. The three-day trial membership plan will automatically convert to our monthly package after the end of your three-day trial period, if you don't cancel your membership before this. Rebilling will occur only when your current payment period expires and not before. If you do not want to continue to use the site you can cancel the automatic billing any time before the collection date.</p>

<h1>I canceled but you are still billing me</h1>

<p>This is embarrassing. Please inform us about an unauthorized charge and our customer service center will correct the problem immediately. Thank you for your patience and we apologize for this inconvenience.</p>

<h1>How do I cancel my subscription?</h1>

<p>To cancel your subscription and repeat billing, please switch to the web version of the site and perform the following actions:<br/>
1. Press your avatar in the upper-right corner of any site page.<br/>
2. Choose ‘My Settings’ from the drop-down menu.<br/>
3. Press the ‘Billing History’ and then the ‘Deactivate subscription’ buttons.<br/>
4. Follow further instructions.<br/>
You must go through the cancellation procedure (enter your password, choose a reason for cancellation). During this procedure you will have an opportunity to check if your email address is correct so that you can successfully receive a cancellation code (sent within an hour) and stop your repeat billing. To complete the subscription cancellation process, you must return to the cancellation page and enter the cancellation code that you received in your email.<br/>
<strong>NOTE: Please do not press the ‘Deactivate subscription’ button more than once, as the cancellation code you have received will then be invalid and you will have to wait for another code.</strong></p>

<h1>How do I know my membership was canceled?</h1>

<p>You will receive a confirmation email to the email address that was specified in your account at the time of cancellation.<br/>
Upon cancellation, there will be no further charges to your account.<br/>
Upon expiration, your membership will revert to the Free status and you can upgrade at any time.<br/>
&nbsp;</p>
      </div>
    </div>
  </section>
</div>)
		
	

}
