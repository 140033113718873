import React, { useEffect,useContext } from "react";
import Context from './context';


export default function Benefits(user) {
	
	 const  context = useContext(Context)


	 var traduzione = context.traduzione.translate.benefits

	return (   <section class="benefits">
      <div class="center">
        <div class="benefits_wrap">
          <div class="benefits_title title">{traduzione.garanzia}</div>
          <div class="benefits_items">
            <div class="benefits_item benefits_item__1">
              <div class="benefits_item-icon icon icon__benefit-1"></div>
              <div class="benefits_item-title">
                <h3 class="benefits_item-title-text">
                  {traduzione.rimborso}                </h3>
              </div>
              <div class="benefits_item-subtitle">
                <p class="benefits_item-subtitle-text">
                  {traduzione.desc_rimborso}               </p>
              </div>
            </div>
            <div class="benefits_item benefits_item__2">
              <div class="benefits_item-icon icon icon__benefit-2"></div>
              <div class="benefits_item-title">
                <h3 class="benefits_item-title-text">
                   {traduzione.riservetezza}                      </h3>
              </div>
              <div class="benefits_item-subtitle">
                <p class="benefits_item-subtitle-text">
                  {traduzione.desc_riservatezza}                </p>
              </div>
            </div>
            <div class="benefits_item benefits_item__3">
              <div class="benefits_item-icon icon icon__benefit-3"></div>
              <div class="benefits_item-title">
                <h3 class="benefits_item-title-text">
                  {traduzione.sicurezza}                </h3>
              </div>
              <div class="benefits_item-subtitle">
                <p class="benefits_item-subtitle-text">
                  {traduzione.desc_sicurezza}                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>)


}
