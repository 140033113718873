 export const translate = 
    {
		"sezione1": {
			"benvenuto":"Welcome to the customer support",
			"faq":" Billing Questions",
			"titolo": "Why is there a charge on my card?",
			"descrizione": "If you have any concern with the charge to your card account, we will assist you in any way to help retrieve all the information about the charges. Use our toll-free numbers to get immediate help regarding any issue. ",
			"telefono":"+34932207148",
			"contattaci":"Contact us",
			"esempio_estratto":"Bank Statement Sample",
			"per_saperne_di_piu":"Learn more"
		},
		"benefits":{
			"garanzia":"100% guarantee",
			"rimborso":"Money back",
			"desc_rimborso":"You can request a refund once you are not satisfied with our products. ",
			"riservetezza":"Confidentiality",
			"desc_riservatezza":"Any personal information you provide is totally safe with us. ",
			"sicurezza":"Security",
			"desc_sicurezza":"All the transactions are completely secure. ",

		},
		"contact_us":{
			"altre_domande":"Any further questions? ",
			"desc_domande":"Our Customer Care is here to help if you've got a problem. Please fill out all the fields so we can learn more about your needs and respond in the shortest time possible. ",
			"chiamaci":" Call us on one of our toll-free numbers: ",
			"numero":"+34932207148",
			"nome":"Name",
			"email":"Email",
			"numero_user":"Full phone number with a country code",
			"data_transazione":"Date of transaction",
			"importo":"Transaction amount",
			"altre_info":"Any other information",
			"compila_carta":" Please fill the form with the first six and the last four digits of the card that has been charged: ",
			"acconsento_privacy":"I hereby consent to my personal data being processed for the purpose of receiving replies from you regarding my request for information.",
			"bottone_contattaci":"Contact us now",
			"error_name":"Name is required",
			"error_email":"Email is required",
			"error_phone":"The phone is required",
			"error_data_trans":"The Transaction Date is required",
			"error_import":"The amount is required",
			"error_carta":"Enter credit card numbers",
			"error_checkbox":"Accept the terms and conditions",
			"ok":"Your request has been sent successfully",
			"ko":"There are some problems, try again later"
		},
		"footer":{
			"privacy":"Privacy Policy",
			"termini":"Terms",
			"faq":"Billing Questions"
		}
	}