import React, { useEffect,useContext } from "react";
import { Link  } from 'react-router-dom';
import Context from './context';


export default function Footer(user) {
	
	 const  context = useContext(Context)
	 

	 var traduzione = context.traduzione.translate.footer

	return ( <footer class="footer">
    <div class="center">
      <div class="footer_wrap">
        <div class="footer_links">
          <Link class="footer_links-item"  to="/privacy">{traduzione.privacy}</Link>
          <Link class="footer_links-item"  to="/terms">{traduzione.termini}</Link>
          <Link class="footer_links-item"  to="/faq">{traduzione.faq}</Link>
        </div>
        <div class="footer_copyright">
          <p class="footer_row">
                      </p>
                      <p class="footer_row"></p>
                    <p class="footer_row footer_site-domain">Ganko Media Sagl Centro Galleria 3 - Via cantonale 4 - 6928 Manno - CH</p>
        </div>
      </div>
    </div>
  </footer> )


}
