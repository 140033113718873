import React, { useState,useEffect  } from "react";


export default function Privacy() {

	return (<div class="static-page">
  <section class="center">
    <div class="article">
      <div class="text-page_wrap">
        <h1>Privacy Policy</h1>

<p>1.1 &nbsp;&nbsp;This website is owned by us. As we collect, store and process your personal information to allow us to provide this service to you, and we are required to obtain your consent to allow us to process your data. &nbsp;You provide us your consent when filling in the request form by ticking the consent box. You can also take away that consent whenever you change your mind.<br/>
1.2&nbsp;You have a legal right to request a copy of the data that we hold on you. Copies are free, and we aim to respond within 14 days (in Europe within 30 days). To get a copy of your data please email our data protection officer.<br/>
1.3 &nbsp;&nbsp;You can amend, correct or ask us to delete the data we hold on you by contacting our data protection officer.<br/>
1.4 &nbsp;&nbsp;If you live in Europe and use our services, you have the right to have all of your data erased when you stop using the services and close your account. We may need to keep a record of certain data for security purposes (to help police in case of criminal activity) or for payment processing (to prove to banks that we were authorized to take payments from you if you pay to use the services). Further detail on how long we keep data can be found below. If you wish to erase all your data, please contact our Support team.<br/>
1.5 &nbsp;&nbsp;Our Privacy Policy relates only to information that we obtain from you. If you visit a website operated by a third party through a link included on this website, your information might be used differently by the operator of the linked website.<br/>
1.6 &nbsp;&nbsp;The information you provide to us when using this website will be processed for the following reasons:<br/>
1.6.1 To administer your user account with us;<br/>
1.6.2 To process any request for advice or information supplied by us;<br/>
1.6.3 To notify you of our bulletins, information packs, products and services unless you select not to receive these from us;<br/>
1.6.4 To notify you about the products and services of other third parties who we cooperate with unless you select not to receive these from us;<br/>
1.6.5 To customize this website according to your interests;<br/>
1.6.6 To comply with our statutory and regulatory obligations;<br/>
1.6.7 To process any orders you make through our website. If you do not wish to receive additional information from us, you can let us know by ticking the appropriate box at sign up.<br/>
1.7 The types of information we will collect about you include:<br/>
1.7.1 Your name and address;<br/>
1.7.2 Your phone number and email address;<br/>
1.7.3 Your occupation;<br/>
1.7.4 Your interests;<br/>
1.7.5 The information included in any online services to which you subscribe;<br/>
1.7.6 Details about the services you specifically request;<br/>
1.7.7 Information you complete in relation to our client surveys or promotions.<br/>
1.8 We will never collect sensitive information about you without your explicit consent.<br/>
1.9 Any information that we collect about you is stored electronically on our database. It may also be printed and stored in our filing system.<br/>
1.10 We will keep your personal information confidential except to the extent that you have posted it for publication or display, and the cases when we are compelled to disclose it by law (for example where fraud or other crime is involved) or to comply with an instruction of a regulatory body of competent jurisdiction. From time to time we may be called upon to demonstrate our maintenance of certain professional standards as set by appropriate authorities and / or to satisfy our auditors and / or to comply with regulations in relation to money laundering or other statutory requirements. If you do not consent to our production of your details for this purpose only as an exception to our duty of confidentiality, you can let us know by ticking the appropriate box at sign-up.<br/>
1.11 The personal information which we hold will be held securely to ensure no unauthorized disclosure or access. When you register a user account with us you will be required to adhere to the security provisions set out in our Terms and Conditions.<br/>
1.12 Marketing<br/>
1.12.1 We may like to contact you in the future for marketing purposes. By agreeing to these terms you are giving us your consent to do so. If you would not like us to contact you concerning our services and offers that may be of interest to you then please check the appropriate box within the 'My account' area when signed in.<br/>
1.12.2 We may pass the information we collect about you to other members of our group or to companies with which we have joint ventures or strategic alliances. We will pass your information to carefully selected companies that we work with in order for them to contact you for the purposes of marketing similar or related services that we think may be of interest to you. If you would not like us to pass your information on to third parties for this purpose then please check the appropriate box within the 'My account' area when signed in.<br/>
1.12.3 You may refuse any marketing communication from us in the future at any time by emailing us.<br/>
1.13 Complaints. If you have a complaint regarding any breach of this Privacy Policy, please contact us. When we receive the complaint, we will review it and ensure that we are in compliance with our Privacy Policy and all applicable laws.<br/>
1.14 Cookies. When visiting our web pages, we would like to use tracking technologies which record and store information about you automatically. This can include using a "cookie" which would be stored on your browser. However, we will not do this unless you give to us your express permission for this to happen. If you agree to give this permission, then please tick the box set out at the bottom of these terms. If we cannot use such technology it is possible that certain functionality of this site might be affected and you might not be able to access certain Web pages. This information does not identify you personally. We automatically collect and store only the following information about your visit:<br/>
1.14.1 The internet domain and IP address from where you access our website;<br/>
1.14.2 The type of browser software and operating system used to access our website;<br/>
1.14.3 The date and time you access our website;<br/>
1.14.4 The pages you enter, visit and exit our website from;<br/>
1.14.5 If you linked to our website from another website, the address of that website.<br/>
We use this information to help us identify clickstream patterns, to improve our website and to learn about the number of visitors to our website and the types of technology our visitors use. We only use this information to ensure that our Web pages stay compatible with a selection of browsers and systems and thereby ensure that the pages appeal to a wide audience.<br/>
1.15 Transfer of information outside the European Economic Area (EEA). We reserve the right to appoint an Internet service provider to host our website on our behalf which may be situated outside the EEA. In addition, the information appearing on your profile may be accessed by members from outside the EEA, who will be bound by this Privacy Policy and by the Terms and Conditions. By agreeing to our Terms and Conditions of use of our website you consent to any transfer of your personal information outside the EEA. This may be necessary for us to achieve the purposes set out above. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.</p>
      </div>
    </div>
  </section>
</div>)		
	

}
