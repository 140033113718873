import React, { useEffect,useContext } from "react";
import Context from './context';
import { useForm, reset   } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';


export default function ContactUs(user) {
	
	 const  context = useContext(Context)
	 const { register, handleSubmit, formState: { errors }   } = useForm();

		 var traduzione = context.traduzione.translate.contact_us


     const onSubmit = (data) => {
      //https://cron.trkmyclick.com/descriptorReq
   

          return fetch("http://localhost:3333/descriptorReq", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
          .then(response => {
            if(response.status == 200){
              alert(traduzione.ok)
              window.location.reload();
            }else{
              alert(traduzione.ko)
            }
          })
          
     }





	return (  <section className="contact-us" id="contactUsBlock">
      <div className="center">
        <div className="contact-us_wrap">
          <div className="contact-us_title title">
            {traduzione.altre_domande}          </div>
          <div className="contact-us_subtitle subtitle">
             {traduzione.desc_domande}           </div>
          <div className="contact-us_form-container">
            <div className="contact-us_form-wrap">
              <div className="contact-us_phone-block phone-block">
                <div className="phone-block_wrapp">
                  <div className="phone-block_title">
                    {traduzione.chiamaci}                    </div>
                  <div className="phone-block_numbers">
                                          <div className="phone-block_numbers-item">
                        <a href={"tel:"+traduzione.numero}className="phone-block_item-link">
                          <i className="phone-block_item-icon phone-icon phone-icon__es"></i>
                          <span className="phone-block_item-number"> {traduzione.numero}     </span>
                        </a>
                      </div>
                                      </div>
                </div>
              </div>
   
              <form className="contact-us_form" onSubmit={handleSubmit(onSubmit)} id="contactUsFrom">
                  {traduzione.error_nome}
                <div className="contact-us_item-wrapper contact-us_item-wrapper__half contact-us_item__required">
                  <input {...register('nome', { required: traduzione.error_name })} className="name contact-us_item"  name="nome" placeholder={traduzione.nome}/>
                </div>

                <div className="contact-us_item-wrapper contact-us_item-wrapper__half contact-us_item__required">
                  <input {...register('email', { required: traduzione.error_email })}  className="email contact-us_item" type="email" name="email" placeholder={traduzione.email}/>
                </div>
               
                <div className="contact-us_item-wrapper contact-us_item-wrapper__third contact-us_item__required">
                  <input {...register('telefono', { required: traduzione.error_telefono, pattern: /[0-9]{5,15}/ })}   className="phone contact-us_item contact-us_item-tel"  name="telefono" placeholder={traduzione.numero_user}/>
                </div>

                <div className="contact-us_item-wrapper contact-us_item-wrapper__guarter contact-us_item__required">
                  <input {...register('data_trans', { required: traduzione.error_data_trans })}  className="date contact-us_item"  name="data_trans" placeholder={traduzione.data_transazione}/>
                </div>
                
                <div className="contact-us_item-wrapper contact-us_item-wrapper__guarter contact-us_item__required">
                  <input {...register('importo', { required: traduzione.error_importo })}  className="amount contact-us_item"  name="importo" placeholder={traduzione.importo}/>
                </div>

                <div className="contact-us_item-wrapper contact-us_item-wrapper__big contact-us_item__required">
                  <textarea {...register('altre_info')} className="message contact-us_item contact-us_item-message" name="altre_info" placeholder={traduzione.altre_info}></textarea>
                </div>
    
                <div className="contact-us_card-block">
                  <label className="contact-us_card-block-name">
                    {traduzione.compila_carta}                  </label>
                  <div className="contact-us_card-block-container">
                    <div className="contact-us_item-wrapper">
                      <input {...register('quattro', { required: traduzione.error_carta})}  pattern= ".{4}" maxLength={4}  className="number contact-us_item contact-us_item-number contact-us_item-number__1"  name="quattro" placeholder="0000"/>
                    </div>
                    <div className="contact-us_item-wrapper">
                      <input  {...register('due', { required: traduzione.error_carta })} pattern= ".{2}" maxLength={2}  className="number contact-us_item contact-us_item-number contact-us_item-number__2"  name="due" placeholder="00"/>
                    </div>
                    <div className="contact-us_item-wrapper">
                      <input className="number contact-us_item contact-us_item-number contact-us_item-number__3"  name="CardNumber[num3]" placeholder="XX" value="XX" disabled/>
                    </div>
                    <div className="contact-us_item-wrapper">
                      <input  className="number contact-us_item contact-us_item-number contact-us_item-number__4"  name="CardNumber[num4]" placeholder="XXXX" value="XXXX" disabled/>
                    </div>
                    <div className="contact-us_item-wrapper">
                      <input  {...register('sei', { required: traduzione.error_carta})}  pattern= ".{4}" maxLength={4} className="number contact-us_item contact-us_item-number contact-us_item-number__5"  name="sei" placeholder="0000"/>
                    </div>
            
                  </div>
                </div>
                <label className="contact-us_form-confirm">
                  <input type="checkbox" name="confirm" id="checkbox" {...register('checkbox', { required: traduzione.error_checkbox })} className="contact-us_form-checkbox"/>
                  <svg width="57" height="46" viewBox="0 0 57 46" className="contact-us_form-checkbox-icon">
                    <g fill="#82CF8F">
                      <path d="M1.496 26.98l23.25 18.198c1.69 1.323 4.124 1.016 5.438-.684 1.313-1.7 1.01-4.15-.68-5.473L6.254 20.825c-1.69-1.323-4.124-1.016-5.438.684-1.313 1.7-1.01 4.15.68 5.473z"/>
                      <path d="M50.024 1.707L24.64 40.04c-1.167 1.762-.694 4.14 1.056 5.316 1.75 1.174 4.114.698 5.28-1.063L56.36 5.96c1.167-1.762.694-4.14-1.056-5.316-1.75-1.174-4.114-.698-5.28 1.063z"/>
                    </g>
                  </svg>
                  <span className="contact-us_form-checkbox-text">{traduzione.acconsento_privacy}</span>
                </label>
                {errors && (
                  <ul>{
                   Object.values(errors).map((value, index) => {
                     return (<li>{value.message}</li>)
                   })}
                   </ul>
                )}
              

                <div className="contact-us_form-submit-wrap">
                  <input type="submit" className="contact-us_form-submit js-contact-us_form-submit btn" value={traduzione.bottone_contattaci}/>
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </section> )


}
